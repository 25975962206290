
* {
  font-family: "Inter";
}

html, body {
  margin: 0;
  padding: 0;
}

pre {
  display: flex;
  margin: 0px;
  overflow: auto;
  white-space: pre;
}

a {
  text-decoration: none;
  padding: 0px;
  margin: 0px;
}

button {
  border-width: 0px;
}
